import { Component, OnInit, Input, Output, OnChanges } from '@angular/core';
import { EventEmitter } from 'events';
import { Router, NavigationEnd } from '@angular/router';
import { HelperService } from '../../services/helper.service';


@Component({
  selector: 'app-podcast',
  templateUrl: './podcast.component.html',
  styleUrls: ['./podcast.component.scss']
})

export class PodcastComponent implements OnInit, OnChanges {

  public hash: string = "5657c9a1dd1596e8a769981e75f01814";
  public status: string = 'paused';
  public audio: any = {};
  @Input() media: string;
  @Input() title: string;
  @Input() subtitle: string;
  // @Input() width: string;
  // @Input() height: string;

  @Output() onLoad: EventEmitter;
  @Output() onStart: EventEmitter;
  @Output() onFinish: EventEmitter;
  // public audio: HTMLAudioElement;
  public progress: number = 0;
  totalTime: string = '00:00';
  ellapsedTime: string = '00:00';


  constructor(public router: Router) {
    this.router.events.subscribe((data)=>{
      if(data instanceof NavigationEnd){
        if(this.audio.pause){
          this.audio.pause();
          this.status = 'paused';
          this.ellapsedTime = '00:00';
          this.audio = new Audio;
        }
      }
    })

  }


  ngOnChanges(change) {
    if(change['media']){
      this.initPlayer();
    }
  }
  ngOnInit() {
    this.initPlayer();
  }
  initPlayer(){
    if(this.media){
      this.audio = new Audio();
      this.audio.onloadeddata = (data)=>{
        console.log(data);
        this.calculateTotalValue();
        setInterval(()=>{
          this.setCurrentPosition();
        },250)
        // this.audio.play();
      }
      this.audio.src = this.media;
    }
  }
  calculateTotalValue() {
    let minutes = Math.floor(this.audio.duration / 60);
    let seconds_int = this.audio.duration - minutes * 60;
    seconds_int = seconds_int * 10;
    let seconds_str = seconds_int.toString();
    let seconds = seconds_str.substr(0, 2);
    console.log('seconds_str', seconds_str);
    this.totalTime = minutes + ':' + seconds;
  }

  calculateCurrentValue() {
    let current_minute = Math.round(this.audio.currentTime / 60 % 60);
    let current_seconds_long = this.audio.currentTime % 60;
    let current_seconds = Math.round(current_seconds_long);
    this.ellapsedTime = (current_minute < 10 ? "0" + current_minute : current_minute) + ":" + (current_seconds < 10 ? "0" + current_seconds : current_seconds);
  }
  setCurrentPosition(){
    if(this.audio.duration && this.audio.currentTime){
      this.progress = Math.round(this.audio.currentTime / this.audio.duration * 10000) / 100;
      this.calculateCurrentValue();
    }else{
      this.progress = 0;
    }
  }
  play(){
    this.audio.play();
    this.status = 'playing';
  }
  pause(){
    this.audio.pause();
    this.status = 'paused';
  }

  onLoadEmit(evt){
    this.onLoad.emit(evt)
  }
  onStartEmit(evt){
    this.onStart.emit(evt)
  }
  onFinishEmit(evt){
    this.onFinish.emit(evt)
  }


}
