import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { HelperService } from '../../services/helper.service';
import { TranslateService } from '../../services/translate.service';


@Component({
  selector: 'app-publicacoes',
  templateUrl: './publicacoes.component.html',
  styleUrls: ['./publicacoes.component.scss']
})
export class PublicacoesComponent implements OnInit {

  public loaded:boolean = false;
  public currentContent:any = false;
  public items:any = [{
    id: '',
    featured: '',
    title: '',
    shortDescription: '',
    description: '',
    credits: ''
  }]



constructor(
    public api : ApiService,
    public helper: HelperService,
    public translate: TranslateService,
    public route: ActivatedRoute

  ) {
  }

  ngOnInit() {
    this.loaded = true;
    this.helper.firstLoad = false;
  }
  nav(item){
    this.currentContent = item;
  }

}
