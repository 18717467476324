import { Component, OnInit, HostListener } from '@angular/core';
import { ContentComponent } from '../../components/content/content.component';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { HelperService } from '../../services/helper.service';
import { TranslateService } from '../../services/translate.service';


@Component({
    selector: 'app-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss']
})

export class DetailsComponent implements OnInit {

    @HostListener('document:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        switch (event.keyCode) {
            case 27:
                this.closeModal();
                break;
            case 39:
                if (this.modalContent !== false) this.navModal(1);
                break;
            case 37:
                if (this.modalContent !== false) this.navModal(-1);
                break;
        }
    }

    public modalContent: any = false;
    public isMobile:any = false;
    public videos = [];
    public images = [];
    public items = [];

    public mockVideos = [{
        id: '',
        title: '',
        description: '',
        thumb: ''
    }];

    public mockImages = [{
        id: '',
        title: '',
        date: '',
        description: '',
        thumb: '',
        fileId: ''
    }];



    public loaded: boolean = false;
    public isAbout: boolean = false;
    public content: any = {
        title: '',
        description: '<p></p>'
    };

    constructor(
        public api: ApiService,
        public helper: HelperService,
        public translate: TranslateService,
        public route: ActivatedRoute,
        public router: Router
    ) {
        this.router.events.subscribe((data) => {
            if (data instanceof NavigationEnd) {
                // console.log(this.helper.podcastAudio);
                // if(this.helper.podcastAudio.pause){
                //   this.helper.podcastAudio.pause();
                // }
                this.loaded = false;
                this.getInitialData();
            }
        })
        this.helper.firstLoad = false;
        let currentPath = this.route.url['_value'][0].path;
        let hasPlaylist = [];
        let hasMosaic = [];

        // if(this.api.useMock){
        //   if(hasMosaic.indexOf(currentPath)>=0){
        //     this.images = this.mockImages;
        //   }
        //   if(hasPlaylist.indexOf(currentPath)>=0){
        //     this.videos = this.mockVideos;
        //   }
        // }
        // let id;
        // if(this.api.useMock){
        //   id = '3974';
        // }else{
        //   id = this.route.params['_value'].id;
        // }

        //     podcast: "http://www.hochmuth.com/mp3/Tchaikovsky_Nocturne__orch.mp3",
        //     podcast_title: "Título do depoimento auctor quis bibendum",
        //     images: ['assets/images/_mock/alexandre-farias1.jpg','assets/images/_mock/alexandre-farias2.jpg','assets/images/_mock/alexandre-farias3.jpg'],
        // }


        // this.api._get('contents/'+id).then((data)=>{
        //   this.content = data;
        //   if(hasMosaic.indexOf(currentPath)>=0){
        //     // ?? Verificar como deve carregar a galeria de imagens
        //   }
        //   if(hasPlaylist.indexOf(currentPath)>=0){
        //     this.api._get('collections/'+this.route.params['_value'].id+'/playlist/').then((playlistData)=>{
        //       this.content.related = [];
        //       let loadedCount = 0;
        //       for(var i in playlistData.items){
        //         this.api._get('collections/'+this.route.params['_value'].id+'/playlist/'+playlistData.items[i].id).then((playlistDataDetails)=>{
        //           playlistDataDetails.title = playlistDataDetails.translatedContent.title;
        //           playlistDataDetails.content = playlistDataDetails.translatedContent.shortDescription;
        //           playlistDataDetails.featured_image = playlistDataDetails.featured ? playlistDataDetails.featured : playlistDataDetails.thumbnail;
        //           this.content.related.push(playlistDataDetails);
        //           loadedCount++;
        //           if(loadedCount == playlistData.items.length){
        //             this.loaded = true;
        //             this.helper.firstLoad = false;
        //           }
        //         })
        //       }
        //     })
        //   }else{
        //     this.loaded = true;
        //     this.helper.firstLoad = false;
        //   }
        // })
    }

    sidenavSelect(item) {
        let alias = this.helper.slugify(item['primeira-linha-do-nome_br'] + ' ' + item['segunda-linha-do-nome_br']);
        this.router.navigateByUrl('/dramaturgos/' + alias + '/sobre');
    }

    getData(id) {
        this.content = {};
        this.api._get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/dramaturgias/dramaturgos/' + id + '.json').then((resp: any) => {
            this.content.id = resp['id-linha-do-nome_br'];
            this.content.alias = this.helper.slugify(resp['primeira-linha-do-nome_br'] + ' ' + resp['segunda-linha-do-nome_br']);
            this.content.title = resp['primeira-linha-do-nome_br'] + ' ' + resp['segunda-linha-do-nome_br'];
            this.content.name = resp['primeira-linha-do-nome_br'];
            this.content.nameWidth = resp['primeira-linha-nome-largura_br'] ? resp['primeira-linha-nome-largura_br'] : 60;
            this.content.lastname = resp['segunda-linha-do-nome_br'];
            this.content.lastnameWidth = resp['segunda-linha-nome-largura_br'] ? resp['segunda-linha-nome-largura_br'] : 100;
            this.content.subtitle = resp['primeira-citacao_br'];
            this.content.quote_pos = resp['citacao-do-dramaturgo-interna_br'];
            this.content.content = resp['content_texto-pre-galeria_br'];
            this.content.content2 = resp['content_texto-pos-galeria_br'];
            this.content.color = resp['cor-hexadecimal_br'];
            this.content.podcast = resp['url-do-podcast_br'];
            this.content.podcast_title = resp['titulo-podcast_br'];
            this.content.podcast_subtitle = resp['subtitulo-podcast_br'];
            this.content.podcast_subtitle = resp['subtitulo-podcast_br'];
            this.content.nameSize = 8.4;
            this.content.lastnameSize = 8;
            this.content.showLogo = true;
            this.getGallery(this.content.alias);
            document.querySelector('app-details').scrollTo(0, 0);
        }).catch(() => {
            this.loaded = true;
        });

    }

    getGallery(alias) {
        this.api._get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/dramaturgias/galerias/list.json').then((r: any) => {
            this.content.images = [];
            let unordered = {};
            r.rows.forEach((item) => {
                if (item.pagina == alias) {
                    unordered[item.ordem_br] = item;
                }
            });

            Object.keys(unordered).sort().forEach((key) => {
                this.content.images.push(unordered[key]);
            });
            this.loaded = true;

        }).catch(() => {
            this.loaded = true;
        });

    }

    getMosaicBackground(image) {
        return 'url("' + image + '")';
    }
    mosaicClick(image) {
        this.modalContent = image;
        this.modalContent.template = 'image';
        this.helper.darkMode = true;
    }
    videoClick(video) {
        this.modalContent = video;
        this.modalContent.template = 'video';
        this.helper.darkMode = true;
    }
    closeModal() {
        this.modalContent = false;
        this.helper.darkMode = false;
    }
    navModal(direction) {
        let template = this.modalContent.template;
        let collection = this[template + 's'];
        let currentId = this.modalContent.id;
        for (let i in collection) {
            let item = collection[i];
            if (item.id === currentId) {
                if (parseInt(i) === 0 && direction < 0) {
                    this.modalContent = collection[collection.length - 1];
                } else if ((parseInt(i) + 1) >= collection.length && direction > 0) {
                    this.modalContent = collection[0];
                } else {
                    this.modalContent = collection[parseInt(i) + direction];
                }
                this.modalContent.template = template;
            }
        }
    }
    nav(item) {
        // this.router.navigateByUrl(this.router.url + '/' + item.id);
        this.content.featured_video = item.fileId;
        // this.content.
        delete this.content.related;

    }
    getInitialData() {

        this.content = {};
        if (!this.route.params['_value'].alias) {

            this.api._get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/dramaturgias/sobre/KEES88ELQO.json').then((r: any) => {
                // this.api._get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/dramatur:gias/sobre/KC3KO19FD6.json').then((r: any) => {
                    // this.items = r.rows;
                // });
                this.items = r.rows;
                this.content.id = r['id'];
                this.content.showLogo = false;
                this.content.alias = 'sobre';
                this.content.title = 'DRAMATURGIAS';
                this.content.subtitle = r['sobre-o-podcast_br'];
                this.content.nameSize = 10;
                this.content.name = 'DRAMATURGIAS';
                this.content.nameWidth = 100;
                this.content.lastname = '';
                this.content.lastnameWidth = 100;
                this.content.color = '#c16969';
                this.content.content = r['content_texto-sobre_br'];
                this.content.quote_pre = r['citacao_br'];
                this.content.content2 = r['content_ficha-tecnica_br'];
                this.content.podcast = r['url-do-podcast_br'];
                this.content.podcast_title = r['titulo-podcast_br'];
                this.content.podcast_subtitle = r['subtitulo-podcast_br'];
                this.content.content_after_quote_pre = r['content_texto-apos-a-citacao_br'];
                this.isAbout = true;
                this.getGallery('sobre');
                document.querySelector('app-details').scrollTo(0, 0);
            }).catch(() => {
                this.loaded = true;
            });

        } else {
            this.content.showLogo = true;
            this.api._get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/dramaturgias/dramaturgos/list.json').then((r: any) => {
                // console.log('dramaturgos', r.rows);
                this.items = r.rows;
                this.items = this.items.sort(function(a, b){
                    if(a['primeira-linha-do-nome_br'] < b['primeira-linha-do-nome_br']) { return -1; }
                    if(a['primeira-linha-do-nome_br'] > b['primeira-linha-do-nome_br']) { return 1; }
                    return 0;
                });
                r.rows.forEach(item => {
                    let alias = this.helper.slugify(item['primeira-linha-do-nome_br'] + ' ' + item['segunda-linha-do-nome_br']);
                    if (alias == this.route.params['_value'].alias) {
                        this.getData(item.id);
                    }
                });

            }).catch(() => {
                this.loaded = true;
            });

        }
    }
    ngOnInit() {
        if(window.innerWidth <= 992){
            this.isMobile = true;
        }
    }

}
