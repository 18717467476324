import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '../../services/translate.service';
import { ApiService } from '../../services/api.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  @Input() list:any = [];
  public loaded:boolean = false;
  constructor(
    public api: ApiService,
    public helper: HelperService,
    public router: Router,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    let ids = [];
    this.list = [];
    switch(this.router.url){
      case '/espetaculos':
        ids = [34728,34720];
        break;
      case '/trilogia-poetica':
          ids = [34736,34743,36427];
        break;
      case '/webseries':
          ids = [34685,34717];
          break;
        case '/extras':
            this.list = [
              {}
            ]
          break;
        case '/publicacoes':
            this.list = [
              {}
            ]
          break;
      }
      let loadedCount = 0;
      if(ids.length>0){

        for(let i in ids){
          this.api._get('contents/' + ids[i]).then((data)=>{

            this.list.push({
              featured_image:data.featured,
              id:data.id,
              title:data.translatedContent.title,
              content:data.translatedContent.shortDescription,
            });
            loadedCount++;
            if(loadedCount == ids.length){
              this.loaded = true;
              this.helper.firstLoad = false;
            }

          });
        }
      }else{
        this.loaded = true;
        this.helper.firstLoad = false;
      }
  }
  listItemClick(item){
    this.router.navigateByUrl(this.router.url + '/' + item.id);
  }

}
