import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { HelperService } from '../../services/helper.service';
import { BoxComponent } from '../../components/box/box.component';
import { ImageComponent } from '../../components/image/image.component';

@Component({
  selector: 'app-extras',
  templateUrl: './extras.component.html',
  styleUrls: ['./extras.component.scss']
})
export class ExtrasComponent implements OnInit {

  public loaded:boolean = false;
  public content:any = {};
  public activeTab:string = 'articles';
  public items:any = {
    articles: [{
      published: '08/05/2015',
      featured: 'assets/images/static/extras-1.png',
      title: 'Estímulo ao Movimento',
      content: 'Cadeiras, cordões, bexigas, entre outros elementos do nosso cotidiano podem ter sua função revista quando a criatividade entra jogo e proporciona uma nova exploração. '
    },{
      published: '14/09/2018',
      featured: 'assets/images/static/extras-2.png',
      title: 'Fases da vida: da gestação à puberdade',
      content: 'Professor e coreógrafo com larga experiência no estudo do movimento, Ivaldo Bertazzo tem dedicado a vida a explicar e ensinar como o ser humano pode se exercitar em qualquer idade. '
    },{
      published: '06/03/2017',
      featured: 'assets/images/static/extras-3.png',
      title: 'Alcance dos Sentidos - Ivaldo Bertazzo',
      content: 'Alcance dos Sentidos é o nome do segundo DVD de Ivaldo Bertazzo pelo Selo Sesc. Sucedendo Infinito Movimento, o título amplia o debate e a discussão sobre a consciência do nosso corpo.'
    },{
      published: '06/03/2013',
      featured: 'assets/images/static/extras-4.png',
      title: 'Corpo, pra que te quero?',
      content: 'A parceria do coreógrafo e bailarino Ivaldo Bertazzo com o Sesc tem rendido muitos frutos nas áreas da arte, cidadania, cultura, educação, esporte e saúde.'
    },{
      published: '11/03/2013',
      featured: 'assets/images/static/extras-5.png',
      title: 'Entretanto, move-se',
      content: 'Corpo vivo: reeducação do movimento, de Ivaldo Bertazzo, nos convida a pensar sobre o corpo humano e a tirá-lo de sua zona de conforto.'
    },{
      published: '06/03/2013',
      featured: 'assets/images/static/extras-6.png',
      title: 'Caminhos do corpo',
      content: 'Gesto orientado completa trilogia sobre o método de reeducação do movimento criado por Ivaldo Bertazzo'
    }],
    pictures: [{
      published: '08/05/2015',
      featured: 'assets/images/static/extras-1.png',
      title: 'Estímulo ao Movimento',
      content: 'Cadeiras, cordões, bexigas, entre outros elementos do nosso cotidiano podem ter sua função revista quando a criatividade entra jogo e proporciona uma nova exploração. '
    },{
      published: '14/09/2018',
      featured: 'assets/images/static/extras-2.png',
      title: 'Fases da vida: da gestação à puberdade',
      content: 'Professor e coreógrafo com larga experiência no estudo do movimento, Ivaldo Bertazzo tem dedicado a vida a explicar e ensinar como o ser humano pode se exercitar em qualquer idade. '
    },{
      published: '06/03/2017',
      featured: 'assets/images/static/extras-3.png',
      title: 'Alcance dos Sentidos - Ivaldo Bertazzo',
      content: 'Alcance dos Sentidos é o nome do segundo DVD de Ivaldo Bertazzo pelo Selo Sesc. Sucedendo Infinito Movimento, o título amplia o debate e a discussão sobre a consciência do nosso corpo.'
    },{
      published: '06/03/2013',
      featured: 'assets/images/static/extras-4.png',
      title: 'Corpo, pra que te quero?',
      content: 'A parceria do coreógrafo e bailarino Ivaldo Bertazzo com o Sesc tem rendido muitos frutos nas áreas da arte, cidadania, cultura, educação, esporte e saúde.'
    },{
      published: '11/03/2013',
      featured: 'assets/images/static/extras-5.png',
      title: 'Entretanto, move-se',
      content: 'Corpo vivo: reeducação do movimento, de Ivaldo Bertazzo, nos convida a pensar sobre o corpo humano e a tirá-lo de sua zona de conforto.'
    },{
      published: '06/03/2013',
      featured: 'assets/images/static/extras-6jpg',
      title: 'Caminhos do corpo',
      content: 'Gesto orientado completa trilogia sobre o método de reeducação do movimento criado por Ivaldo Bertazzo'
    }]
  }



  constructor(
    public api : ApiService,
    public helper: HelperService,
    public route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.loaded = true;
    this.helper.firstLoad = false;
  }

}
