import { Component, OnInit, Input, Output } from '@angular/core';
import { HelperService } from '../../services/helper.service';


@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})

export class InfoComponent implements OnInit {

  @Input() title: string;
  @Input() color: string;
  @Input() cta: string;
  @Input() y: string;
  @Input() x: string;
  @Input() wrapperWidth: any = 25;



  constructor(public helper:HelperService) {

  }
  ngOnInit() {
  }

}
