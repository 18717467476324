import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

  @Input('background') background:string;
  @Input('src') src:string;
  @ViewChild('image') image:ElementRef;
  constructor(
    public helper: HelperService,
    private renderer: Renderer2,
    public el: ElementRef

  ) { }

  ngOnInit() {
    if(this.background){
      let bg = this.background;
      let el = this.el.nativeElement;
      this.renderer.setStyle(el, 'background-image', 'url("' + bg + '")');
    }
  }

}
