import { Component, OnInit } from '@angular/core';
import { ContentComponent } from '../../components/content/content.component';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  public content = [{
    name: "Estações do Corpo e do Espírito",
    tubePic: "/files/programacao/197b9a0b/77b3/42e0/b86d/5c196e43b333.jpg",
    description: "<p><b><i>Teste de Legenda</i></b></p><p><b><i>Outro Teste de Legenda</i></b></p><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus ipsam voluptates, sed quis nisi aspernatur alias facere quaerat odio inventore excepturi nostrum dolores corporis maxime suscipit, fuga ex aliquam totam.</p><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus ipsam voluptates, sed quis nisi aspernatur alias facere quaerat odio inventore excepturi nostrum dolores corporis maxime suscipit, fuga ex aliquam totam.</p>"
  },{
    name: "A linguagem expressiva dos corpos",
    tubePic: "/files/programacao/a8605ce9/bd13/41c7/967b/32d7a92497ac.jpg",
    teatured_video: "5657c9a1dd1596e8a769981e75f01814",
    description: "<p><b><i>Teste de Legenda</i></b></p><p><b><i>Outro Teste de Legenda</i></b></p><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus ipsam voluptates, sed quis nisi aspernatur alias facere quaerat odio inventore excepturi nostrum dolores corporis maxime suscipit, fuga ex aliquam totam.</p><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus ipsam voluptates, sed quis nisi aspernatur alias facere quaerat odio inventore excepturi nostrum dolores corporis maxime suscipit, fuga ex aliquam totam.</p>"
  },{
    name: "Corpo Vivo",
    tubePic: "/files/blog/2af33b91-3c9c-4aa2-9d5b-11652049e5d5.jpg",
    description: "<p><b><i>Teste de Legenda</i></b></p><p><b><i>Outro Teste de Legenda</i></b></p><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus ipsam voluptates, sed quis nisi aspernatur alias facere quaerat odio inventore excepturi nostrum dolores corporis maxime suscipit, fuga ex aliquam totam.</p><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus ipsam voluptates, sed quis nisi aspernatur alias facere quaerat odio inventore excepturi nostrum dolores corporis maxime suscipit, fuga ex aliquam totam.</p>"
  }]
  constructor() { }

  ngOnInit() {
  }

}
