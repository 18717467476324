import { Component, HostBinding } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HelperService } from './services/helper.service';
import { ApiService } from './services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
  title = 'dramaturgias';
  public hasFooter:boolean = false;

  @HostBinding('class') private navClass: string;
  constructor(
    public router: Router,
    public api: ApiService,
    public helper: HelperService
  ) {
		router.events.subscribe((val) => {
      if(val instanceof NavigationEnd){
        this.hasFooter = this.router.url.split('/')[1] !== 'home';
        this.navClass = 'pg-' + this.router.url.split('/')[1];
        window.scrollTo(0, 0);
			}
    });
    this.api._get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/dramaturgias/configuraces-do-projeto/KBIERQZ1W6.json').then((r: any) => {
      this.helper.projectTitle =  r['titulo-do-projeto_br'];
    });
  }
}
