import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MockService {
	public mockEndpoints: any = {
		'selections':'selections',
		'order':'order',
		'categories/cloud-tags':'cloud-tags',
		'contents/search?page=0&size=1':'search',
		'contents/search?page=0&size=3':'search',
		'contents/3974':'profile',
		'content':'featured',
		'menu':'menu',
		'content-sources':'content-sources',
		'content-sources/16':'content-source',
		'content-sources/5':'content-source',
		'collections/637/contents':'collection',
		'collections/19083/playlist':'playlist',
	}

  constructor() { }

	public getMockData(url:string){
        console.log(url);
    	let source = this.mockEndpoints[url];
		let mockData = {
			"profile": {
				id: "1",
				title: "Alexandre Dal Farra",
                name: "Alexandre",
                nameSize: 8.4,
                nameWidth:66,
                lastname: "Dal Farra",
                lastnameAlign : "right",
                lastnameSize: 8.4,
                lastnameWidth:100,
                subtitle: "Todo dramaturgo quando encontra com isso percebe que tem ali algo que não é só seu, não só porque é de outro, parece que é algo que está em você e que você não controla, não tem consciência total.",
				quote: "Desconhecido (ou estranho) familiar - Você está procurando dentro de você ou da linguagem coisas que você não reconhece completamente, esse estranho familiar é isto.",
				content: '<p>Alexandre Dal Farra é dramaturgo, diretor e escritor. Autor de diversas peças apresentadas por todo o Brasil e no exterior, suas principais produções foram “Mateus, 10” (2012), “Trilogia Abnegação” (2014-2016), “Branco, o cheiro do lírio e do formol” (2017), “Teorema 21” (2016) e “O Filho” (2015), entre outros, tendo recebido por elas indicações nas principais premiações nacionais, contemplado no prêmio Shell 2012 como melhor autor e finalista do Prêmio Aplauso Brasil. É doutorando pela ECA/USP e autor do romance Manual da Destruição. Encara, com suas peças, temas como religião, política e racismo no Brasil.</p>',
				content2: '<p>TRECHO DA OBRA ABNEGAÇÃO: <br> Só quando estivermos totalmente despidos de nossas esperanças e olharmos pra desgraça do mundo de frente por muito tempo sem nenhuma perspectiva redentora além da realidade nua e crua, só então é que poderemos talvez encontrar uma outra capacidade de imaginar que não se funde na esperança de um sucesso mágico, de uma vitória milagrosa feita de boas ações.</p>',
				podcast: "http://www.hochmuth.com/mp3/Tchaikovsky_Nocturne__orch.mp3",
                podcast_title: "Título do depoimento auctor quis bibendum",
                images: ['assets/images/_mock/alexandre-farias1.jpg','assets/images/_mock/alexandre-farias2.jpg','assets/images/_mock/alexandre-farias3.jpg'],
				color: "#a369c1",
				top: 0,
				left: 0,
            },
			"profiles": [{
				id: "1",
				title: "Emanuel Aragão",
				subtitle: "Como é que faço pra me comunicar com as pessoas e conseguir me sustentar me comunicando com as pessoas?",
				quote: "Cada pessoa é um universo. Como abrir um corte que tenha mais universos? ",
				content: '<p>Emanuel Aragão é formado em Filosofia e Teatro. Trabalha em geral como roteirista, dramaturgo e ator. Mas já publicou um romance e está em processo de edição do segundo. Escreveu muitas peças de teatro e alguns roteiros para cinema e televisão. É autor das peças “nãotemnemnome”, “24por1” e da adaptação de “Hamlet – Processo de Revelação”, com a qual viajou pelo Brasil pelo projeto Palco Giratório. Ultimamente, tem tentado entender como é possível lidar com a escrita e a elaboração de sentido em mídias como o Instagram e o Youtube. Por mais estranho que isso pareça, até para ele.</p>',
				content2: '<p>TRECHO DA OBRA CINE GAIVOTA: <br> Por fim, tudo isto teve uma consequência ainda mais dramática, que alguns poucos tentaram prever, mas foram rapidamente desacreditados: a ausência da experiência da passagem de tempo. E, com isto, ainda mais radicalmente, o desaparecimento do próprio tempo como categoria, mensurável, e assim dele mesmo como substância. O desaparecimento do tempo em si, a humanidade e a vida continuaram existindo, porém o tempo não estava mais lá.</p>',
				podcast: "http://www.hochmuth.com/mp3/Tchaikovsky_Nocturne__orch.mp3",
				podcast_title: "Título do depoimento auctor quis bibendum",
				color: "#69C1A9",
				textIndent: '3em',
				top: 0,
				left: 0,
            }],
            "featured": [
                {
                    "id": 1,
                    "alias": "alexandre-dal-farra",
                    "fullName": "Alexandre Dal Farra",
                    "name": "Alexandre",
                    "nameSize": 8.4,
                    "nameWidth":66,
                    "lastname": "Dal Farra",
                    "lastnameAlign" : "right",
                    "lastnameSize": 8.4,
                    "lastnameWidth":100,
                    "image": "assets/images/_mock/bg-alexandre.png",
                    "color": "#a369c1",
                    "shortDescription": "Territórios, camadas e fendas: encontro com o estranho familiar dentro de si",
                    "shortDescriptionX": 61,
                    "shortDescriptionY": 50,
                    "shortDescriptionWidth": 18,
                    "imageOver": true,
                    "imageBGX": 100,
                    "imageBGY": 38,
                    "imageX": -5,
                    "imageY": 6

                },
                {
                    "id": 2,
                    "alias": "dione-carlos",
                    "fullName": "Dione Carlos",
                    "name": "Dione Carlos",
                    "nameAlign" : "center",
                    "nameSize": 8.4,
                    "nameWidth":85,
                    "color": "#bec169",
                    "shortDescription": "Do inconsciente ao grande encontro com o público: olhar para si, olhar para o mundo",
                    "image": "assets/images/_mock/bg-diane.png",
                    "imageOver": true,
                    "shortDescriptionX": 61,
                    "shortDescriptionY": 66,
                    "shortDescriptionWidth": 18,
                    "imageBGX": 100,
                    "imageBGY": 38,
                    "imageX": -5,
                    "imageY": -2
                },
                {
                    "id": 3,
                    "alias": "emanuel-aragao",
                    "fullName": "Emanuel Aragão",
                    "name": "Emanuel",
                    "nameAlign" : "right",
                    "nameSize": 8.4,
                    "nameWidth":66,
                    "color": "#68bfa7",
                    "lastname": "Aragão",
                    "lastnameAlign" : "right",
                    "lastnameSize": 8,
                    "lastnameWidth":89,
                    "shortDescription": "Dramaturgia feita em cena com o público: cada pessoa, um universo",
                    "image": "assets/images/_mock/bg-emanuel.png",
                    "imageOver": false,
                    "shortDescriptionX": 61,
                    "shortDescriptionY": 50,
                    "shortDescriptionWidth": 18,
                    // "imageOver": true,
                    "imageBGX": 100,
                    "imageBGY": 38,
                    "imageX": -5,
                    "imageY": 9
                },
                {
                    "id": 4,
                    "alias": "francisco-carlos",
                    "fullName": "Francisco Carlos",
                    "name": "Francisco",
                    "lastname": "Carlos",
                    "nameFontSize": 8.8,
                    "nameWidth": 66,
                    "lastnameAlign" : "right",
                    "lastnameSize": 8.8,
                    "lastnameWidth":100,
                    "color": "#c19269",
                    "shortDescription": "Antigos e novos colonialismos, antologias indígenas e periferias: da assombração ao sublime",
                    "image": "assets/images/_mock/bg-francisco.png",
                    "imageOver": true,
                    "shortDescriptionX": 61,
                    "shortDescriptionY": 50,
                    "shortDescriptionWidth": 18,
                    // "imageOver": true,
                    "imageBGX": 100,
                    "imageBGY": 38,
                    "imageX": -5,
                    "imageY": 9
                }
            ],
            "menu": [
                {
                    "id": 1,
                    "alias": "podcast-de-apresentacao",
                    "title": "Podcast de Apresentação",
                    "image": "assets/images/_mock/alexandre-farias-thumb.jpg"
                },
                {
                    "id": 2,
                    "alias": "alexandre-dal-farra",
                    "title": "Alexandre Dal Farra",
                    "image": "assets/images/_mock/alexandre-farias-thumb.jpg"
                },
                {
                    "id": 3,
                    "alias": "dione-carlos",
                    "title": "Dione Carlos",
                    "image": "assets/images/_mock/dione-carlos-thumb.jpg",
                },
                {
                    "id": 4,
                    "alias": "emanuel-aragao",
                    "title": "Emanuel Aragão",
                    "image": "assets/images/_mock/emanuel-aragao-thumb.jpg",
                },
                {
                    "id": 5,
                    "alias": "francisco-carlos",
                    "title": "Francisco Carlos",
                    "image": "assets/images/_mock/francisco-carlos-thumb.jpg"
                }
            ]
		}
		return mockData[source];

	}

}
