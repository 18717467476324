import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from 'events';


@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})

export class GalleryComponent implements OnInit {

  public activeImage:number = 0;

  @Input() images: any = [];

  @Output() onLoad: EventEmitter;
  @Output() onStart: EventEmitter;
  @Output() onFinish: EventEmitter;


  constructor() { }

  ngOnInit() {
    // this.initPlayer();
  }
  nav(dir){
    let newIdx = this.activeImage + dir;
    if(newIdx<0) newIdx = this.images.length -1;
    if(newIdx>this.images.length -1) newIdx = 0;
    this.changeImage(newIdx);
  }
  changeImage(idx){
    this.activeImage = idx;
  }

  onLoadEmit(evt){
    this.onLoad.emit(evt)
  }
  onStartEmit(evt){
    this.onStart.emit(evt)
  }
  onFinishEmit(evt){
    this.onFinish.emit(evt)
  }


}
