import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from 'events';

declare var SambaPlayer:any;

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})

export class PlayerComponent implements OnInit {

  public hash: string = "5657c9a1dd1596e8a769981e75f01814";
  @Input() media: string;
  @Input() width: string;
  @Input() height: string;
  
  @Output() onLoad: EventEmitter;
  @Output() onStart: EventEmitter;
  @Output() onFinish: EventEmitter;


  constructor() { }

  ngOnInit() {
    this.initPlayer();
  }
  initPlayer(){

    var player = new SambaPlayer("samba-player", { //player é o ID do elemento html que ele vai inserir o iframe
      height: this.height,
      width: this.width,
      ph: this.hash,//Player Hash do projeto
      m: this.media,//MidiaID
      playerParams: { //Veja a lista de Parâmetros suportados
          enableShare: true
      },
      events: { //Funcoes que escutam os eventos do player
          "onLoad": this.onLoadEmit, //Sendo que eventListener deve ser uma função já presente na página
          "onStart": this.onStartEmit,
          "onFinish": this.onFinishEmit
      }
    }); 
  }

  onLoadEmit(evt){
    this.onLoad.emit(evt)
  }
  onStartEmit(evt){
    this.onStart.emit(evt)
  }
  onFinishEmit(evt){
    this.onFinish.emit(evt)
  }


}
