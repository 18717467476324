import { Component, OnInit } from '@angular/core';
// // import { BannerComponent } from '../banner/banner.component';
// // import { ImageComponent } from '../image/image.component';
import { ApiService } from '../../services/api.service';
import { HelperService } from '../../services/helper.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    public loaded: boolean = false;
    public animated: boolean = false;
    public animated2: boolean = false;
    public activeProfile: any = false;
    public profiles: any = [];
    public contentData : any;
    public homeData : any;
    public backgroundImage : any = 'bg-black.png';
    public currentHomeSection: any = 'initial'; // initial, covers

    public animated3: boolean = true;
    public enter: boolean = false;

    public banners = ['assets/images/bg-home.png'];
    public banners2 = ['assets/images/stage.jpg'];
    public subscribedRoute: any;

    constructor(
        public helper: HelperService,
        public api: ApiService,
        public ac: ActivatedRoute,
        public router: Router
    ) {
    }
    ngOnInit() {
        this.getCover();
        this.getHome();

        if (this.ac.snapshot.params.alias) {
            this.currentHomeSection = 'covers';
        } else if (this.ac.snapshot.routeConfig.path == 'home') {
            this.currentHomeSection = 'initial';
        } else if (this.ac.snapshot.routeConfig.path == 'sobre') {
            this.currentHomeSection = 'covers';
        }
        this.getProfiles();

        this.subscribedRoute = this.router.events.subscribe((data) => {
            if (data instanceof NavigationEnd) {
                this.profiles.forEach(item => {
                    // console.log(item);
                    if (this.currentHomeSection == 'sobre' && item.alias == 'podcast-de-apresentacao' || this.currentHomeSection == 'covers' && item.alias == this.ac.snapshot.params.alias) {
                        this.selectProfile(item);
                    }
                });
            }
        })

        if(this.router.url == '/abertura'){
            this.enter = true;
            this.currentHomeSection = 'initial';
        }
        this.helper.firstLoad = false;
        // this.api._get('content').then((responseData) => {
        //     this.profiles = responseData;
        // })
    }
    getCover(){
        this.api._get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/dramaturgias/capa/KC3HN93DA1.json').then((r: any) => {
            this.contentData = r;
            this.backgroundImage = r['imagem-de-fundo-url'];
            this.banners = [r['imagem-do-dramaturgo-url']];
            setTimeout(() => {
                this.animated = true;
            }, 1000)
            setTimeout(() => {
                this.animated2 = true;
            }, 2000)
            this.loaded = true;
        });
    }

    getHome(){
        this.api._get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/dramaturgias/apresentacao-do-projeto/KC3HOD2OKV.json').then((r: any) => {
            this.homeData = r;
            this.banners2 = [r['imagem-de-fundo-url']];
            // this.backgroundImage = r['imagem-de-fundo-url'];
            // this.banners = [r['imagem-do-dramaturgo-url']];
        });
    }

    getProfiles() {
        this.api._get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/dramaturgias/dramaturgos/list.json').then((r: any) => {
            this.profiles = r.rows;
            this.profiles = this.profiles.map((item) => {
                item.fullName = item['primeira-linha-do-nome_br'] + ' ' + item['segunda-linha-do-nome_br'];
                item.alias = this.helper.slugify(item.fullName);
                item.name = item['primeira-linha-do-nome_br'];
                item.nameSize = 8.4;
                item.nameWidth = item['primeira-linha-nome-largura_br'] ? item['primeira-linha-nome-largura_br'] : 60;
                item.lastname = item['segunda-linha-do-nome_br'];
                item.lastnameAlign = "right";
                item.lastnameSize =  8.4;
                item.lastnameWidth = item['segunda-linha-nome-largura_br'] ? item['segunda-linha-nome-largura_br'] : 100;
                item.image = item['imagem-do-dramaturgo-url'];
                item.color = item['cor-hexadecimal_br'];
                item.shortDescription = item['breve-descricao_br'];
                item.shortDescriptionX = item['breve-descricao-posicao-eixo-horizontal-numero_br'] ? item['breve-descricao-posicao-eixo-horizontal-numero_br'] : 61;
                item.shortDescriptionY = item['breve-descricao-posicao-eixo-vertical-numero_br'] ? item['breve-descricao-posicao-eixo-vertical-numero_br'] : 50;
                item.shortDescriptionWidth = 18;
                item.imageOver = true;
                // item.imageBGX = 100;
                // item.imageBGY = 0;
                // item.imageSize = item['tamanho-imagem-do-dramaturgo_br'];
                // item.imageX = item['posicao-horizontal-imagem-do-dramaturgo_br'];
                // item.imageY = item['posicao-vertical-imagem-do-dramaturgo_br'];
                return item;
            });
            // console.log(this.profiles);

            // call-to-action_br: "hml-dramaturgias.sescdigital.cloud/dramaturgos/Francisco-Carlos/sobre"
            // citacao-do-dramaturgo-interna_br: "Todo dramaturgo quando encontra com isso percebe que tem ali algo que não é só seu; não só porque é de outro, parece que é algo que está em você e que você não controla, não tem consciência total."
            // cor-hexadecimal_br: "#69c1a9"
            // id: "KDUQAJO8TM"
            // imagem-de-fundo: "sescdigital-cms-lite/dramaturgias/dramaturgos/images/FundoKDUQA33625.jpg"
            // imagem-de-fundo-url: "https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/dramaturgias/dramaturgos/images/FundoKDUQA33625.jpg"
            // imagem-do-dramaturgo: "sescdigital-cms-lite/dramaturgias/dramaturgos/images/DRAMATURGIAS_ALEXANDRE DAL FARRA_09_06_2018_FOTO RENATO NASCIMENTO-8KDUQ9WU9VE.png"
            // imagem-do-dramaturgo-url: "https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/dramaturgias/dramaturgos/images/DRAMATURGIAS_ALEXANDRE%20DAL%20FARRA_09_06_2018_FOTO%20RENATO%20NASCIMENTO-8KDUQ9WU9VE.png"
            // primeira-citacao_br: "Todo dramaturgo quando encontra com isso percebe que tem ali algo que não é só seu, não só porque é de outro, parece que é algo que está em você e que você não controla, não tem consciência total."
            // texto-pos-galeria: "<p><i><strong>Todo dramaturgo quando encontra com isso percebe que tem ali algo que não é só seu, não só porque é de outro, parece que é algo que está em você e que você não controla, não tem consciência total.Todo dramaturgo quando encontra com isso percebe que tem ali algo que não é só seu, não só porque é de outro, parece que é algo que está em você e que você não controla, não tem consciência total.Todo dramaturgo quando encontra com isso percebe que tem ali algo que não é só seu, não só porque é de outro, parece que é algo que está em você e que você não controla, não tem consciência total.Todo dramaturgo quando encontra com isso percebe que tem ali algo que não é só seu, não só porque é de outro, parece que é algo que está em você e que você não controla, não tem consciência total.</strong></i></p>"
            // texto-pre-galeria: "<p><i><strong>Todo dramaturgo quando encontra com isso percebe que tem ali algo que não é só seu, não só porque é de outro, parece que é algo que está em você e que você não controla, não tem consciência total.</strong></i></p>"
            // url-do-podcast_br: "http://pvbps-sambavideos.akamaized.net/account/2472/21/2020-08-06/audio/86351c5fcaf3bca31e66f48c6a82f347/DRAMATURGIAS_Francisco_Carlos_mastered.mp3"

            this.profiles.forEach(item => {
                // console.log(item);
                if (this.currentHomeSection == 'sobre' && item.alias == 'podcast-de-apresentacao' || this.currentHomeSection == 'covers' && item.alias == this.ac.snapshot.params.alias) {
                    this.selectProfile(item);
                }
            });
            // {
            //     "id": 1,
            //     "alias": "alexandre-dal-farra",
            //     "fullName": "Alexandre Dal Farra",
            //     "name": "Alexandre",
            //     "nameSize": 8.4,
            //     "nameWidth":66,
            //     "lastname": "Dal Farra",
            //     "lastnameAlign" : "right",
            //     "lastnameSize": 8.4,
            //     "lastnameWidth":100,
            //     "image": "assets/images/_mock/bg-alexandre.png",
            //     "color": "#a369c1",
            //     "shortDescription": "Territórios, camadas e fendas: encontro com o estranho familiar dentro de si",
            //     "shortDescriptionX": 61,
            //     "shortDescriptionY": 50,
            //     "shortDescriptionWidth": 18,
            //     "imageOver": true,
            //     "imageBGX": 100,
            //     "imageBGY": 38,
            //     "imageX": -5,
            //     "imageY": 6

            // }
            // console.log(r.rows);
        });
        // this.api._get('content').then((responseData) => {
        //     this.profiles = responseData;
        //     this.profiles.forEach(item => {
        //         if (this.currentHomeSection == 'sobre' && item.alias == 'podcast-de-apresentacao' || this.currentHomeSection == 'covers' && item.alias == this.ac.snapshot.params.alias) {
        //             this.selectProfile(item);
        //         }
        //     });
        // });
    }


    selectProfile(profile) {
        // console.log('selectProfile', profile);
        this.activeProfile = profile;
    }
}
