import { Component, OnInit, Input } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-title',
    templateUrl: './title.component.html',
    styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {

    @Input() firstLine: any = { title: 'DRAMATURGIAS', align: 'left' };
    @Input() secondLine: any = {};
    @Input() color: string = '#c16969';
    @Input() showLogo: boolean = false;
    @Input() projectTitle: string = '';


    constructor(public helper: HelperService, public router: Router) {

    }

    ngOnInit() {
    }
    goHome(){
        this.router.navigate(['home']);
    }

}
