import { Component, OnInit, Input, Output } from '@angular/core';
import { HelperService } from '../../services/helper.service';
import { EventEmitter } from 'events';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';


@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})

export class NavComponent implements OnInit {

    @Input() mode: string;
    @Output() onSelect: EventEmitter;
    items: any = [];
    currentPage: any = '';
    currentAlias: any = '';
    currentIndex: any = 0;
    public firstPositionIndex: any = 1;
    public secondPositionIndex: any = 2;
    public thirdPositionIndex: any = 3;
    public subscribedRoute:any;
    public offset: any = 0;
    public maxItems: any = 3;

    constructor(
        public helper: HelperService,
        public api: ApiService,
        public ac: ActivatedRoute,
        public router: Router
    ) {

        this.api._get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/dramaturgias/menu-do-rodape/list.json').then((r: any) => {
            this.items = r.rows;
            this.items = this.items.map((item) => {
                item.title = item['titulo-do-menu_br'];
                item.alias = this.helper.slugify(item['titulo-do-menu_br']);
                item.image = item['thumb-url'];
                return item;
            });
            this.items = this.items.sort(function(a, b){
                if(a['titulo-do-menu_br'] < b['titulo-do-menu_br']) { return -1; }
                if(a['titulo-do-menu_br'] > b['titulo-do-menu_br']) { return 1; }
                return 0;
            });
            this.items.map((item, idx) => {
                let alias = '';
                if(item['titulo-do-menu_br']){
                    alias = this.helper.slugify(item['titulo-do-menu_br']);
                }else{
                    alias = this.helper.slugify(item['title']);
                }
                if(alias == this.ac.snapshot.params.alias){
                    // this.currentIndex = idx + 1; // só tem +1 se o sobre for adicionado na mao
                    this.currentIndex = idx;
                    this.offset = this.currentIndex;
                    // if (idx > (this.items.length - this.maxItems)) {
                    //     this.offset = this.items.length - this.maxItems;
                    // }else{
                    //     this.offset = idx;
                    // }
                }

            });
            // this.items.unshift({
            //     title: 'Sobre',
            //     alias: 'sobre',
            // });
            // foi pedido para nao buscar nos dramaturgos
            // this.api._get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/dramaturgias/dramaturgos/list.json').then((r2: any) => {
            //     r2.rows.map((item) => {
            //         item.title = item['primeira-linha-do-nome_br'] + ' ' + item['segunda-linha-do-nome_br'];
            //         item.alias = this.helper.slugify(item.title);
            //         item.image = item['imagem-do-dramaturgo-url'];
            //         this.items.push(item);
            //     });
            //     this.items.unshift({
            //         title: 'Sobre',
            //         alias: 'sobre',
            //     });
            //     this.items.map((item, idx) => {
            //         let alias = '';
            //         if(item['titulo-do-menu_br']){
            //             alias = this.helper.slugify(item['titulo-do-menu_br']);
            //         }else{
            //             alias = this.helper.slugify(item['title']);
            //         }
            //         if(alias == this.ac.snapshot.params.alias){
            //             this.currentIndex = idx;
            //             if (idx > (this.items.length - this.maxItems)) {
            //                 this.offset = this.items.length - this.maxItems;
            //             }else{
            //                 this.offset = idx;
            //             }
            //         }

            //     })
            //     // this.allItems = Object.assign([], this.items);
            //     // this.items = this.items.slice(0,13);
            // });
        });

        // this.api._get('menu').then((responseData) => {
        //     this.items = responseData;
        //     if(this.api.useMock){
        //         this.items.concat(this.items);
        //     }
        //     this.items.unshift({
        //         title: 'Sobre',
        //         alias: 'sobre',
        //     })
        // });

        this.subscribedRoute = this.router.events.subscribe((data) => {
            if (data instanceof NavigationEnd) {
                let path = data.urlAfterRedirects.split('/');
                let alias = path[2];

            }
        })
    }
    offsetNav(dir, autoNav = false){
        if (autoNav == true) {
            this.currentIndex += dir;
            // console.log(this.currentIndex);
            // console.log(this.items[this.currentIndex]);
            let alias = '';
            console.log(this.items[this.currentIndex]);
            if(this.items[this.currentIndex]['titulo-do-menu_br']){
                alias = this.helper.slugify(this.items[this.currentIndex]['titulo-do-menu_br']);
            }else{
                alias = this.helper.slugify(this.items[this.currentIndex]['title']);
            }
            // console.log(alias);
            this.router.navigate([this.getURL(alias)]);
        }
        if(this.offset + dir < 0) return;
        if(this.offset + dir > this.items.length - 1) return;
        this.offset += dir;
    }
    offsetVisibility(idx){
        if(idx < this.offset) return false
        if(idx >= this.offset + this.maxItems) return false
        return true
    }
    ngOnInit() {
        if(window.innerWidth <= 992){
            this.maxItems = 13;
        }
    }

    getURL(alias) {
        let url = '/dramaturgos/' + alias;
        if(['sobre', 'abertura', 'home'].indexOf(alias) >=0){
            url = '/' + alias;
        }
        return url;
    }

    getCurrentIndex(){

    }

    showRightArrow(){
        return this.currentIndex < this.items.length -1;
    }

    showLeftArrow(){
        return this.currentIndex > 0;
    }

    showBullets(idx){
        // console.log(idx, this.offset);
         console.log(this.currentIndex);
        if (this.currentIndex + 1 - this.maxItems > 0){
            if(idx < this.currentIndex +1 - this.maxItems ) return false;
            if(idx >= this.maxItems + (this.currentIndex + 1) - this.maxItems ) return false;
        }else{
            if(idx >= this.maxItems ) return false;
        }
        return true
    }

    select(item) {
        this.onSelect.emit(item);
    }

}
