import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { HelperService } from '../../services/helper.service';


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})

export class SidenavComponent implements OnInit, OnChanges {

  @Output() onItemSelect = new EventEmitter;
  @Input() items: any = [];
  @Input() currentId: number;
  @Input() color: any;

  public selectedItem: number = 0;

  constructor(public helper:HelperService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['currentId']){
      this.selectById(this.currentId);
    }
  }
  ngOnInit() {
  }
  selectById(id){
    for(let i in this.items){
      console.log(this.items[i]['id'], id);
      if(this.items[i]['id'] == id){
        this.items[i].selected = true;
        this.selectedItem = parseInt(i);
      }else{
        this.items[i].selected = false;
      }
    }

  }

  nav(item){
    this.selectById(item['id']);
    this.onItemSelect.emit(item);
  }
  scroll(dir){
    if(this.selectedItem <= 0 && dir < 0){
        return false;
    }
    if(this.selectedItem >= this.items.length - 1 && dir > 0){
        return false;
    }
    this.selectedItem += dir;
    this.nav(this.items[this.selectedItem]);
    let scroller = document.getElementById('sidenav-scroller');
    scroller.scrollTop = scroller.children[0]['offsetHeight'] * this.selectedItem;
  }
}