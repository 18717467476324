import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '../../services/translate.service'
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public isMobileActive:boolean = false;
  public isMobile:boolean = false;
  public activeSubmenu:number = -1;
  public items:any = [];

  constructor(
    public translate: TranslateService,
    public router: Router,
    public api: ApiService,
    public helper: HelperService
  ) { }

  ngOnInit() {
    if(window.innerWidth <= 992){
        this.isMobile = true;
    }
    this.api._get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/dramaturgias/menu-do-rodape/list.json').then((r: any) => {
        this.items = r.rows;
        this.items = this.items.map((item) => {
            item.title = item['titulo-do-menu_br'];
            item.alias = this.helper.slugify(item['titulo-do-menu_br']);
            item.image = item['thumb-url'];
            return item;
        });
        this.items = this.items.sort(function(a, b){
            if(a['titulo-do-menu_br'] < b['titulo-do-menu_br']) { return -1; }
            if(a['titulo-do-menu_br'] > b['titulo-do-menu_br']) { return 1; }
            return 0;
        });
        // this.items.unshift({
        //     title: 'Sobre',
        //     alias: 'sobre',
        // });
    });

    // this.api._get('menu').then((responseData) => {
    //   this.items = responseData;
    // });

  }

  getRouterLink(item){
    let url = '';

    if (['abertura', 'home', 'sobre'].indexOf(item.alias) >=0 ) {
        url = '/' + item.alias;
    }else{
        url = '/dramaturgos/'+item.alias
    }
    return url;

  }
  toggleMobile(){
      this.isMobileActive = !this.isMobileActive;
      this.helper.isMenuClosed = !this.isMobileActive;
  }

}
