import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  @Input() layout:string = '';
  @Input() cover:any = false;
  @Input() images:any = [];
  @Input() bgPosition:any = {x: 0, y: 0};
  @Input() bgTranslate:any = {x: 0, y: 0};
  @Input() bgSize:any = 0;

  constructor() { }

  ngOnInit() {

  }

}
